import React from "react"
import Seo from "../components/SEO"
import Header from "../components/Header/Header"
import Module from "../components/Module/Module"
import Title from "../components/Title/Title"
import DiaryForm from "../components/Diary/DiaryForm"

const MyPageTemplate = (contentfulPage) => {
  const {
    title,
    header,
    modules,
    locale
  } = contentfulPage

  return (
    <>
      <Seo title={title} />

      <Header
        title={header.title}
        content={header.content}
        imageUrl={header.backgroundImage.file.url}
        slides={header.slides}
        quote={header.quote}
      />
      {modules && (
        <>
          {modules.map((item, index) => {
            switch (item.template) {
              case "title":
                return (
                  <Title
                    key={index}
                    id={item.contentfulid}
                    title={item.title}
                    imageUrl={item.backgroundImage.file.url}
                  />
                )

              case "diary":
                return (
                  <DiaryForm  labels={item.labels} locale={locale}/>
                )

              default:
                return (
                  <Module
                    key={index}
                    id={item.contentfulid}
                    style={item.style}
                    content={item.content}
                  />
                )
            }
          })}
        </>
      )}
    </>
  )
}

export default MyPageTemplate
