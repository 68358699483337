import React from "react"
import { graphql, navigate } from "gatsby"
import MyPageTemplate from "../templates/page-template"
import Seo from "../components/SEO"
import Layout from "../components/Layout"
import { isLoggedIn } from "../services/auth"

const MyPage = ({ data: { contentfulPage, allContentfulFooter }, location }) => {
  let logged = isLoggedIn();
  if (!logged && location.pathname !== `/user/auth`) {
    console.warn("Not logged in");
    navigate("/user/auth")
    return null
  }

  let contentfulFooter = (allContentfulFooter.nodes.filter(x => x.locale == contentfulPage.locale)[0]) ?? {};
  let extendedFooter = { ...contentfulFooter, "dateOfPreparation": contentfulPage.dateOfPreparation }

  return (
    logged && (
      <>
        <Seo title={contentfulPage.title} />
        <Layout pageClass={contentfulPage.pageClass} currentPage={contentfulPage.slug} footer={extendedFooter} locale={contentfulPage.locale} >
          <MyPageTemplate {...contentfulPage} />
        </Layout>
      </>
    )
  )
}

export const data = graphql`
  query myPageQuery($slug: String) {
    contentfulPage(slug: {eq: $slug}) {
      title
      pageClass
      slug
      locale
      header {
        title
        content {
          content
        }
        backgroundImage {
          file {
              url
          }
        }
        slides {
          title,
          posterImage{
            url
          },
          video{
            url
          },
          quote,
          transcript {
            transcript
          }
        }        
      }
      dateOfPreparation
      modules {
        ... on ContentfulSection {
          title          
          contentfulid
          style {
            internal {
              content
            }
          }
          content {
            content 
            childMdx{
              body
            }
          }
          template
          labels {
            mon
            tue
            wed
            thu
            fri
            sat
            sun
            download_form
            fill_form
            date
            compare_to_yesterday
            compare_to_yesterday_2
            cough
            fatigue
            fever      
            generate_pdf
            how_long
            how_severe
            impact_on_life
            infection
            infusion_day
            infusion_symptoms
            infusion_well_tolerated
            itching
            gain
            loss
            muscle_pain
            night_sweats
            yes
            no
            not_at_all
            other_symptoms
            pain
            skin_rash
            sleep_last_night
            stressful_today
            swollen
            symptom_title
            throat_problem
            today_felt
            tummy_issues
            unchanged
            very
            very_bad
            very_good
            water_retention
            weight_change
            worse
            better
            blood_pressure
            breath_shortnetss
            swollen_1
            where_on_body
            water_1
            water_2
            other
            describe_symptom
            symptom_title_1
            symptom_title_2
            how_severe_1
            how_severe_2
          }
        }
        ... on ContentfulTitle {
          contentfulid        
          title
          backgroundImage {
            file {
                url
            }
          }
          template        
        }
      }
    }
    allContentfulFooter(filter: {type: {eq: "home"}}) {
      nodes {
        locale
        type
        refs {
          refs
        }
        disclaimer {
          disclaimer
        }
      }
    }   
  }
`

export default MyPage
