import React, { useState, useEffect } from "react";
import Slider from "react-slick";
import { connect } from "react-redux"

const mapStateToProps = () => {
    return {};
}

const mapDispatchToProps = dispatch => {
    return {
        slideChanged: (tr, prevIndex) => {            
            dispatch({ type: `SLIDE_CHANGE`, payload: { transcript: tr, prevSlide: prevIndex } });
        }
    }
}

const JourneySlider = ({ children, slides, slideChanged }) => {
    const settings = {
        slidesToShow: 1,
        slidesToScroll: 1,
        focusOnSelect: true,
        centerMode: true,
        centerPadding: 0,
        infinite: true,
        arrows: true,
        className: 'journey-slider'
    };

    const [quote, setQuote] = useState(slides[0].quote);

    const beforeChange = async (prev, next) => {
        if (prev !== next) {
            setQuote(slides[next].quote);
            slideChanged(slides[next].transcript?.transcript, prev);
        }
    }

    useEffect(() => {        
        slideChanged(slides[0].transcript?.transcript, -1);
    }, [])

    return (
        <>
            <div className="slider-trim">
                <Slider {...settings} beforeChange={beforeChange}>
                    {children}
                </Slider>
            </div>
            <p className="quote" dangerouslySetInnerHTML={{ __html: quote }}></p>
        </>
    )
}

const ConnectedJourneySlider = connect(mapStateToProps, mapDispatchToProps)(JourneySlider)

export default ConnectedJourneySlider