import React from "react"
import DiaryOption from "./DiaryOption"
import DiaryScale from "./DiaryScale"
import DiaryRow from "./DiaryRow"
import { Page, Font, Image, Text, View, Document, StyleSheet } from '@react-pdf/renderer';
import { format } from 'date-fns'
import nexaregular from "../../fonts/nexa-regular/NexaRegular.ttf"
import nexabold from "../../fonts/nexa-bold/NexaBold.ttf"

const DiaryPdf = ({ data, labels, locale }) => {
    Font.register({
        family: "Nexa Regular",
        format: "truetype",
        src: nexaregular
    });

    Font.register({
        family: "Nexa Bold",
        format: "truetype",
        src: nexabold
    });

    const styles = StyleSheet.create({
        page: {
            backgroundColor: '#ffffff',
            fontFamily: "Nexa Regular",
            fontSize: "8px",
            fontWeight: "bold",
            color: "#563465"
        },
        container: {
            margin: "30px 60px 10px 60px"
        },
        row: {
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            paddingTop: "5px",
            paddingBottom: "4px",
        },
        columnLeft: {
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-start",
            //flexBasis: 0,
            //flexFlow: "row nowrap",        
            //backgroundColor: "yellow"
        },
        column: {
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-start",
            flexGrow: 1,
            //backgroundColor: "#CCFF66"
        },
        columnRight: {
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-end",
            //backgroundColor: "orange",
        },
        label: {
            paddingRight: "10px"
        },
        input: {
            textDecoration: "underline",
            textDecorationStyle: "dotted"
        },

        hrsolid: {
            borderBottom: '0.5px solid #a18ca8'
        },
        hrdotted: {
            borderBottom: '0.5px dotted #f2cbd2'
        },

        oneColumn: {
            width: "35%"
        },
        twoColumn: {
            width: "70%"
        },
        subtext: {
            fontSize: "6px",
            color: "#CCC2D0"
        },
        inputEmpty: {
            width: "210px",
            //backgroundColor: "green"
        },
        firstColumn: {
            width: "33%",
            //backgroundColor: "yellow"
        },
        lastColumn: {
            width: "20%",
            //backgroundColor: "orange"
        },
        footer: {
            position: "absolute",
            height: "50px",
            left: "0",
            bottom: "0",
            width: "100%",
            backgroundColor: "#563465",
            color: "white",

        },
        footerMain: {
            fontSize: "13px",
            marginTop: "10px",
            marginLeft: "10px"
        },
        footerSub: {
            fontSize: "8px",
            marginTop: "5px",
            marginLeft: "10px"
        },
        imageFooterIT: {
            marginTop: "81px"
        },
    });

    const rows = [
        { id: 'infection', text: labels.infection },
        { id: 'fever', text: labels.fever },
        { id: 'muscle', text: labels.muscle_pain },
        { id: 'pressure', text: labels.blood_pressure },
        { id: 'lymph', text: labels.swollen_1, newline: labels.where_on_body },
        { id: 'mouth', text: labels.throat_problem },
        { id: 'tummy', text: labels.tummy_issues },
        { id: 'weight', text: labels.weight_change, options: [labels.gain, labels.loss] },
        { id: 'water', text: labels.water_1, subtext: labels.water_2, newline: labels.where_on_body },
        { id: 'fatigue', text: labels.fatigue },
        { id: 'breath', text: labels.breath_shortnetss },
        { id: 'sweats', text: labels.night_sweats },
        { id: 'rash', text: labels.skin_rash },
        { id: 'itching', text: labels.itching },
        { id: 'cough', text: labels.cough },
        { id: 'pain', text: labels.pain },
        { id: 'otherSymptoms', text: labels.other, newline: labels.describe_symptom }
    ];

    const days = [labels.mon, labels.tue, labels.wed, labels.thu, labels.fri, labels.sat, labels.sun];

    return (
        <Document>
            <Page size="A4" style={styles.page}>
                {locale === "en-US" &&
                    <>
                        <View>
                            <Image
                                style={styles.image}
                                source="../../img/patient-diary-header-banner-2.jpg"
                            />
                        </View>
                    </>
                }
                {locale === "it-IT" &&
                    <>
                        <View>
                            <Image
                                style={styles.image}
                                source="../../img/patient-diary-header-banner-it.jpg"
                            />
                        </View>
                    </>
                }
                {locale === "pt_BR" &&
                    <>
                        <View>
                            <Image
                                style={styles.image}
                                source="../../img/patient-diary-header-banner-pt.jpg"
                            />
                        </View>
                    </>
                }
                patient-diary-header-banner-it.jpg
                <View style={styles.container}>
                    <View style={[styles.row, styles.hrsolid]}>
                        <View style={styles.columnLeft}>
                            {days.map((day, index) => {
                                return (
                                    <DiaryOption key={index} text={day} val={data.day} />
                                )
                            })}
                        </View>
                        <View style={styles.columnRight}>
                            <Text style={{ paddingRight: "10px", paddingLeft: "5px" }}>
                                {labels.date}:
                            </Text>
                            <Text style={styles.input}>
                                {format(data.date ? new Date(data.date) : new Date(), 'dd/MM/yyyy')}
                            </Text>
                        </View>
                    </View>
                    {locale === "en-US" &&
                        <>
                            <View style={[styles.row, styles.hrdotted]}>
                                <View style={styles.columnLeft}>
                                    <Text style={styles.label}>Infusion day:</Text>
                                    <DiaryOption text="Yes" val={data.infused} />
                                    <DiaryOption text="No" val={data.infused} />
                                </View>
                                <View style={styles.columnRight}>
                                    <Text style={styles.label}>Was your infusion well tolerated?</Text>
                                    <DiaryOption text="Yes" val={data.wellTolerated} />
                                    <DiaryOption text="No" val={data.wellTolerated} />
                                </View>
                            </View>
                            <View style={[styles.row]}>
                                <View style={styles.columnLeft}>
                                    <Text>If no, make a note of any symptoms you experienced:</Text>
                                </View>
                            </View>
                            <View style={[styles.row, styles.hrsolid]}>
                                <Text>{data.symptomsExperienced}</Text>
                            </View>
                        </>
                    }

                    <View style={[styles.row, styles.hrdotted]}>
                        <View style={[styles.columnLeft, styles.oneColumn]}>
                            <Text>{labels.today_felt}:</Text>
                        </View>
                        <View style={[styles.column, styles.twoColumn]}>
                            <DiaryOption image="face-happy.png" isChecked={data.feeling === 'Happy'} />
                            <DiaryOption image="face-slightlyHappy.png" isChecked={data.feeling === 'SlightHappy'} />
                            <DiaryOption image="face-ok.png" isChecked={data.feeling === 'OK'} />
                            <DiaryOption image="face-slightlyUnhappy.png" isChecked={data.feeling === 'SlightlyUnhappy'} />
                            <DiaryOption image="face-Unhappy.png" isChecked={data.feeling === 'Unhappy'} />
                        </View>
                    </View>

                    <View style={[styles.row, styles.hrdotted]}>
                        <View style={[styles.columnLeft, styles.oneColumn]}>
                            <View>
                                <Text>{labels.compare_to_yesterday} {labels.compare_to_yesterday_2}:</Text>
                            </View>
                        </View>
                        <View style={[styles.column, styles.twoColumn]}>
                            <DiaryOption text={labels.better} val={data.yesterdayFeeling} />
                            <DiaryOption text={labels.worse} val={data.yesterdayFeeling} />
                            <DiaryOption text={labels.unchanged} val={data.yesterdayFeeling} />
                        </View>
                    </View>

                    <View style={[styles.row, styles.hrdotted]}>
                        <View style={[styles.columnLeft, styles.oneColumn]}>
                            <Text>{labels.stressful_today}</Text>
                        </View>
                        <View style={[styles.column, styles.twoColumn]}>
                            <Text style={[styles.label, styles.subtext]}>{labels.not_at_all}</Text>
                            <DiaryScale val={data.stress} />
                            <Text style={styles.subtext}>{labels.very}</Text>
                        </View>
                    </View>

                    <View style={[styles.row, styles.hrsolid]}>
                        <View style={[styles.columnLeft, styles.oneColumn]}>
                            <Text>{labels.sleep_last_night}</Text>
                        </View>
                        <View style={[styles.column, styles.twoColumn]}>
                            <Text style={[styles.label, styles.subtext]}>{labels.very_bad}</Text>
                            <DiaryScale val={data.sleep} />
                            <Text style={styles.subtext}>{labels.very_good}</Text>
                        </View>
                    </View>

                    <View style={[styles.row, styles.hrdotted]}>
                        <View style={[styles.columnLeft, styles.firstColumn]}>
                            <View>
                                <Text>{labels.symptom_title_1}
                                </Text>
                                <Text style={styles.subtext}>{labels.symptom_title_2}</Text>
                            </View>
                        </View>
                        <View style={[styles.column, styles.inputEmpty]}>
                            <Text>{labels.how_long}</Text>
                        </View>
                        <View style={[styles.lastColumn]}>
                            <View>
                                <Text>{labels.how_severe_1}
                                </Text>
                                <Text style={styles.subtext}>{labels.how_severe_2}</Text>
                            </View>
                        </View>
                    </View>

                    {rows.map((row, index) => {
                        return (
                            <DiaryRow key={index} {...row} data={data}></DiaryRow>
                        )
                    })}

                    <View style={[styles.row]}>
                        <View style={styles.columnLeft}>
                            <Text>{labels.impact_on_life}
                            </Text>
                        </View>
                    </View>
                    <View style={[styles.row, styles.hrsolid]}>
                        <Text>{data.symptomsImpact}</Text>
                    </View>
                </View>
                {locale === "en-US" &&
                    <View>
                        <Image
                            style={styles.image}
                            source="../../img/patient-diary-footer-banner.jpg"
                        />
                    </View>
                }
                {locale === "it-IT" &&
                    <View>
                        <Image
                            style={styles.imageFooterIT}
                            source="../../img/patient-diary-footer-banner-it.jpg"
                        />
                    </View>
                }
                {locale === "pt_BR" &&
                    <View>
                        <Image
                            style={styles.imageFooterIT}
                            source="../../img/patient-diary-footer-banner-pt.jpg"
                        />
                    </View>
                }
            </Page>
        </Document >
    )
}

export default DiaryPdf
