import * as React from "react"
import { Text, View, StyleSheet } from '@react-pdf/renderer';
import DiaryOption from "./DiaryOption"
import DiaryScale from "./DiaryScale"

const DiaryRow = ({ id, text, subtext, newline, options, extraLine, data }) => {  
    // if(text && text.length>44){
    //     let firstText = ''
    //     const wrap = (s, w) => s.replace(
    //         new RegExp(`(?![^\\n]{1,${w}}$)([^\\n]{1,${w}})\\s`, 'g'), '$1\n'
    //     );
    // }

    const styles = StyleSheet.create({
        row: {
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            paddingTop: "5px"        
        },
        columnLeft: {
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-start",
            //flexBasis: 0,
            //flexFlow: "row nowrap",        
            //backgroundColor: "yellow"
        },
        column: {
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-start",
            flexGrow: 1,
            //backgroundColor: "#CCFF66"
        },
        columnRight: {
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-end",
            //backgroundColor: "orange",
        },
        inputEmpty: {
            marginLeft: "10px",
            width: "210px"
        },
        hrdotted: {
            borderBottom: '0.5px dotted #f2cbd2',
            paddingBottom: "2px",
        },
        firstColumn: {
            width: "30%"
        },
        lastColumn: {
            width: "22%"
        }
    });

    const checkboxField = id + 'Select';
    const textField = id + 'HowLong';

    return (
        <>        
            <View style={[styles.row]}>
                <View style={[styles.columnLeft, styles.firstColumn, extraLine ? null : styles.hrdotted]}>
                    <DiaryOption text={text} subtext={subtext} newline={newline} isChecked={data[checkboxField]} />
                </View>

                <View style={styles.column}>
                    <Text style={[styles.inputEmpty, styles.hrdotted]}>
                        {data[textField]}
                    </Text>
                </View>
                <View style={[styles.columnRight, styles.lastColumn, extraLine ? null : styles.hrdotted]}>
                    {options && options.map((option, index) => {
                        return (
                            <DiaryOption key={index} text={option} val={data[id]} />
                        )
                    })}
                    {!options && <DiaryScale val={data[id]} />}
                </View>
            </View>
            <View>
                {extraLine &&
                    <Text style={[styles.row, styles.hrdotted]}>
                        Something very very long text to be disaplayed here.
                    </Text>
                }
            </View>
        </>
    )
}

export default DiaryRow
