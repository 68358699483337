import React from "react"
import ConnectedJourneySlider from "../Slider/JourneySlider"
import { ConnectedVideoPlayer } from '../Video/VideoPlayer'

const Header = ({ title, imageUrl, slides, content }) => {
  const style = {
    "background": `#666 url('${imageUrl}') no-repeat center`, "backgroundSize": "cover"
  }

  return (
    <div className="header-module" style={style} >
      <h1 dangerouslySetInnerHTML={{ __html: title }}></h1>
      <p>{content?.content}</p>
      {slides && (
        <ConnectedJourneySlider slides={slides}>
          {slides.map((slide, index) => {
            const { title, posterImage, video } = slide
            return (
              <div className="journey" key={index}>                
                <h2 dangerouslySetInnerHTML={{ __html: title }}></h2>                
                <div className="embed-container video-wrapper'">
                  <ConnectedVideoPlayer posterImage={posterImage.url} src={video.url} index={index}></ConnectedVideoPlayer>
                </div>
              </div>
            )
          })}
        </ConnectedJourneySlider>
      )}

    </div>
  )
}

export default Header
