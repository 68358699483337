import React from "react"

const Title = ({ id, title, imageUrl }) => {
  const style = { "backgroundImage": `url('${imageUrl}')`, "backgroundRepeat": " no-repeat", "backgroundPosition": " center", "backgroundSize": " cover" };

  return (
    <div id={id} className="title-module" style={style}>
      <h2 dangerouslySetInnerHTML={{ __html: title }}></h2>
    </div >
  )
}

export default Title
