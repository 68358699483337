import * as React from "react"
import { Text, View, StyleSheet } from '@react-pdf/renderer';

const nums = [1, 2, 3, 4, 5];

const DiaryScale = ({ val }) => {
    const styles = StyleSheet.create({
        row: {
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center"
        },
        num: {
            marginLeft: "5px",
            marginRight: "10px",        
        },
        circle: {
            borderRadius: "50%",
            // width: "10px",
            // height: "10px",
            padding: "1px",
            // border: "1px solid #000",        
            textAlign: "center",
            fontFamily: "Nexa Bold",
            fontSize: "7px",
            fontWeight: "bold",
            backgroundColor: "#a18ca8",
            display: "inline-block",
            color: "#ffffff"
        },
        number: {
            borderRadius: "50%",
            width: "10px",
            height: "10px",
            padding: "2px",
            backgroundColor: "#000",
            color: "#fff",
            textAlign: "center",
            font: "12px Arial, sans-serif"
        }
    });

    return (               
        <View style={styles.row}>
        {
            nums.map((num, index) => {
                return (
                    <Text key={index} style={[styles.num, num==val?styles.circle:null]}>{num}</Text>
                )
            })
        }
        </View>
    )
}

export default DiaryScale
