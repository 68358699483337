import * as React from "react"
import { Image, Text, View, StyleSheet } from '@react-pdf/renderer';

const DiaryOption = ({ text, val, subtext, newline, isChecked, image }) => {

    const styles = StyleSheet.create({
        label: {
            paddingRight: "15px"
        },
        option: {
            border: "1px solid #CCC2D0",
            appearance: "none",
            width: "8px",
            height: "8px",
            marginRight: "5px",
            textAlign: "center",
            verticalAlign: "middle",
            background: 'transparent url("../img/checked.png") no-repeat center !important'
    
        },
        check: {
            paddingTop: "1px",
            fontSize: "7px",
        },
        subtext: {
            fontSize: "6px",
            color: "#CCC2D0",
            paddingLeft: "5px"
        },
        face: {
            width: "12px",
            height: "12px",
            marginRight: "15px"
        }
    });

    const imagePath = "../img/" + image;
    return (
        <>
            <View style={styles.option}>
                {((val && text === val) || isChecked) &&
                    <Image                        
                        source="../img/checked.png"
                    />}
            </View>

            <View>
                <View style={[styles.label, { display: "flex", flexDirection: "row", alignItems: "center" }]}>
                    <Text>
                        {text}
                    </Text>
                    {subtext && <Text style={styles.subtext}>
                        {subtext}
                    </Text>}
                </View>

                {image &&
                    <View style={[styles.face, {verticalAlign: "middle" }]}>
                        <Image
                            style={styles.image}
                            source={imagePath}
                        />
                    </View>
                }

                {newline && <View><Text>{newline}</Text></View>}
            </View>
        </>)
}

export default DiaryOption
