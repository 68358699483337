import * as React from "react"
import { useState } from 'react';
import { pdf } from '@react-pdf/renderer';
import { saveAs } from 'file-saver';
import DiaryPdf from "./DiaryPdf"

const DiaryForm = ({ locale, labels }) => {
  const downloadForm = async (event) => {
    event.preventDefault(); // prevent page reload
    const blob = await pdf(
      <DiaryPdf data={state} labels={labels} locale={locale} />
    ).toBlob();

    saveAs(blob, 'My-iMCD-Diary.pdf');
  }

  const [state, setState] = useState({
    day: "",
    formDate: "",
    email: "",
    infused: "",
    wellTolerated: "",
    symptomsExperienced: "",
    feeling: "",
    yesterdayFeeling: "",
    stress: "",
    sleep: "",
    infectionSelect: false, infection: "", infectionHowLong: "",
    feverSelect: false, fever: "", feverHowLong: "",
    muscleSelect: false, muscle: "", muscleHowLong: "",
    pressureSelect: false, pressure: "", pressureHowLong: "",
    lymphSelect: false, lymph: "", lymphHowLong: "",
    mouthSelect: false, mouth: "", mouthHowLong: "",
    tummySelect: false, tummy: "", tummyHowLong: "",
    weightSelect: false, weight: "", weightHowLong: "",
    waterSelect: false, water: "", waterHowLong: "",
    fatigueSelect: false, fatigue: "", fatigueHowLong: "",
    breathSelect: false, breath: "", breathHowLong: "",
    sweatsSelect: false, sweats: "", sweatsHowLong: "",
    rashSelect: false, rash: "", rashHowLong: "",
    itchingSelect: false, itching: "", itchingHowLong: "",
    coughSelect: false, cough: "", coughHowLong: "",
    painSelect: false, pain: "", painHowLong: "",
    otherSymptomsSelect: false, otherSymptoms: "", otherSymptomsHowLong: ""
  });

  const inputsHandler = e => {
    const { name, value } = e.target;
    setState(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const checkboxHandler = (e) => {
    const { name, value } = e.target;
    setState(prevState => ({
      ...prevState,
      [name]: !(state[name])
    }));
  }

  return (
    <div className="formWrapper">
      <form>
        <div className="diaryDownload diarySection">
          <div className="inner">
            {locale == "en-US" &&
              <a href="https://assets.ctfassets.net/nchyn0no001y/24j2Gb64Qr0bSTqnpO74ke/6efed5817892c4750b79e075edabd84c/My-iMCD-diary.pdf" title="My iMCD diary" target="_blank">
                <img src="../img/download-patient-diary.png" alt="Download Patient Diary" />
              </a>
            }
            {locale == "it-IT" &&
              <a href="https://assets.ctfassets.net/6h1wbu0b27gl/4T8yqrfsJPorFc36nsv9yd/e64fba1d88e1f248d83624960223352b/myiMCD-Italian-Diary.pdf" title="Il mio DIARIO sull'iMCD" target="_blank">
                <img src="https://images.ctfassets.net/6h1wbu0b27gl/5EQI2qtvmTfkQsdO3nwFN5/989cc5822c8071b9662b917eba8133f8/download-pdf-italia.png" alt="Scarica Diario del paziente" />
              </a>
            } 
            {locale == "pt_BR" &&
              <a href="https://assets.ctfassets.net/nchyn0no001y/24j2Gb64Qr0bSTqnpO74ke/6efed5817892c4750b79e075edabd84c/My-iMCD-diary.pdf" title="Baixe o diário para imprimir e preencher" target="_blank">
                <img src="https://images.ctfassets.net/6h1wbu0b27gl/5EQI2qtvmTfkQsdO3nwFN5/989cc5822c8071b9662b917eba8133f8/download-pdf-italia.png" alt="Baixe o diário para imprimir e preencher" />
              </a>
            }           
            <p dangerouslySetInnerHTML={{ __html: labels.download_form }}></p>
          </div>
        </div>
        <div className="diaryHeader diarySection">
          <h3 dangerouslySetInnerHTML={{ __html: labels.fill_form }}></h3>
        </div>
        <div className="dayDate diarySection">
          <div className="day">
            <div className="fieldset radio">
              <input type="radio" id="dayMon" className="Monday" name="day" value={labels.mon} onChange={inputsHandler} />
              <label>{labels.mon}</label>
            </div>
            <div className="fieldset radio">
              <input type="radio" id="dayTues" className="Tuesday" name="day" value={labels.tue} onChange={inputsHandler} />
              <label>{labels.tue}</label>
            </div>
            <div className="fieldset radio">
              <input type="radio" id="dayWed" className="Wednesday" name="day" value={labels.wed} onChange={inputsHandler} />
              <label>{labels.wed}</label>
            </div>
            <div className="fieldset radio">
              <input type="radio" id="dayThur" className="Thursday" name="day" value={labels.thu} onChange={inputsHandler} />
              <label>{labels.thu}</label>
            </div>
            <div className="fieldset radio">
              <input type="radio" id="dayFri" className="Friday" name="day" value={labels.fri} onChange={inputsHandler} />
              <label>{labels.fri}</label>
            </div>
            <div className="fieldset radio">
              <input type="radio" id="daySat" className="Saturday" name="day" value={labels.sat} onChange={inputsHandler} />
              <label>{labels.sat}</label>
            </div>
            <div className="fieldset radio">
              <input type="radio" id="daySun" className="Sunday" name="day" value={labels.sun} onChange={inputsHandler} />
              <label>{labels.sun}</label>
            </div>
          </div>
          <div className="date">
            <label>{labels.date}:</label>
            <input type="date" name="date" className="datePicker" onChange={inputsHandler} />
          </div>
        </div>
        {locale == "en-US" &&
          <>
            <div className="infusion diarySection">
              <div className="infused">
                <label>{labels.infusion_day}:</label>
                <div className="fieldset radio">
                  <input type="radio" id="infusedYes" className="infusedYes" name="infused" value="Yes" onChange={inputsHandler} />
                  <label>{labels.yes}</label>
                </div>
                <div className="fieldset radio">
                  <input type="radio" id="infusedNo" className="infusedNo" name="infused" value="No" onChange={inputsHandler} />
                  <label>{labels.no}</label>
                </div>
              </div>
              <div className="tolerated">
                <label>{labels.infusion_well_tolerated}</label>
                <div className="fieldset radio">
                  <input type="radio" id="toleratedYes" className="toleratedYes" name="wellTolerated" value="Yes" onChange={inputsHandler} />
                  <label>{labels.yes}</label>
                </div>
                <div className="fieldset radio">
                  <input type="radio" id="toleratedNo" className="toleratedNo" name="wellTolerated" value="No" onChange={inputsHandler} />
                  <label>{labels.no}</label>
                </div>
              </div>
            </div>
            <div className="symptomNotes diarySection">
              <label>{labels.infusion_symptoms}:</label>
              <textarea id="symptoms" name="symptomsExperienced" rows="4" onChange={inputsHandler}></textarea>
            </div>
          </>
        }
        <div className="todayFeeling diarySection">
          <label>{labels.today_felt}:</label>
          <div className="faces">
            <div className="fieldset radio">
              <input type="radio" id="feelingHappy" className="feelingHappy" name="feeling" value="Happy" onChange={inputsHandler} />
              <label className="happy"></label>
            </div>
            <div className="fieldset radio">
              <input type="radio" id="feelingSlightlyHappy" className="feelingSlightlyHappy" name="feeling" value="SlightHappy" onChange={inputsHandler} />
              <label className="slightlyHappy"></label>
            </div>
            <div className="fieldset radio">
              <input type="radio" id="feelingOK" className="feelingOK" name="feeling" value="OK" onChange={inputsHandler} />
              <label className="ok"></label>
            </div>
            <div className="fieldset radio">
              <input type="radio" id="feelingSlightlyUnhappy" className="feelingSlightlyUnhappy" name="feeling" value="SlightlyUnhappy" onChange={inputsHandler} />
              <label className="slightlyUnhappy"></label>
            </div>
            <div className="fieldset radio">
              <input type="radio" id="feelingUnhappy" className="feelingUnhappy" name="feeling" value="Unhappy" onChange={inputsHandler} />
              <label className="unhappy"></label>
            </div>
          </div>
        </div>
        <div className="compared diarySection">
          <div className="labels">
            <label dangerouslySetInnerHTML={{ __html: labels.compare_to_yesterday }}></label><br/>
            <label>{labels.compare_to_yesterday_2}</label>
          </div>
          <div className="options">
            <div className="fieldset radio">
              <input type="radio" id="compareBetter" name="yesterdayFeeling" value={labels.better} onChange={inputsHandler} />
              <label>{labels.better}</label>
            </div>
            <div className="fieldset radio">
              <input type="radio" id="compareWorse" name="yesterdayFeeling" value={labels.worse} onChange={inputsHandler} />
              <label>{labels.worse}</label>
            </div>
            <div className="fieldset radio">
              <input type="radio" id="compareUnchanged" name="yesterdayFeeling" value={labels.unchanged} onChange={inputsHandler} />
              <label>{labels.unchanged}</label>
            </div>
          </div>
        </div>
        <div className="stressful diarySection">
          <label>{labels.stressful_today}</label>
          <div className="options">
            <label>{labels.not_at_all}</label>
            <div className="fieldset radio">
              <input type="radio" id="stressfulOne" className="stressfulOne" name="stress" value="1" onChange={inputsHandler} />
            </div>
            <div className="fieldset radio">
              <input type="radio" id="stressfulTwo" className="stressfulTwo" name="stress" value="2" onChange={inputsHandler} />
            </div>
            <div className="fieldset radio">
              <input type="radio" id="stressfulThree" className="stressfulThree" name="stress" value="3" onChange={inputsHandler} />
            </div>
            <div className="fieldset radio">
              <input type="radio" id="stressfulFour" className="stressfulFour" name="stress" value="4" onChange={inputsHandler} />
            </div>
            <div className="fieldset radio">
              <input type="radio" id="stressfulFive" className="stressfulFive" name="stress" value="5" onChange={inputsHandler} />
            </div>
            <label>{labels.very}</label>
          </div>
        </div>
        <div className="sleep diarySection">
          <label>{labels.sleep_last_night}</label>
          <div className="options">
            <label>{labels.very_bad}</label>
            <div className="fieldset radio">
              <input type="radio" id="sleepOne" className="sleepOne" name="sleep" value="1" onChange={inputsHandler} />
            </div>
            <div className="fieldset radio">
              <input type="radio" id="sleepTwo" className="sleepTwo" name="sleep" value="2" onChange={inputsHandler} />
            </div>
            <div className="fieldset radio">
              <input type="radio" id="sleepThree" className="sleepThree" name="sleep" value="3" onChange={inputsHandler} />
            </div>
            <div className="fieldset radio">
              <input type="radio" id="sleepFour" className="sleepFour" name="sleep" value="4" onChange={inputsHandler} />
            </div>
            <div className="fieldset radio">
              <input type="radio" id="sleepFive" className="sleepFive" name="sleep" value="5" onChange={inputsHandler} />
            </div>
            <label>{labels.very_good}</label>
          </div>
        </div>
        <div className="titles diarySection">
          <label dangerouslySetInnerHTML={{ __html: labels.symptom_title }}></label>
          <label>{labels.how_long}</label>
          <label dangerouslySetInnerHTML={{ __html: labels.how_severe }}></label>
        </div>
        <div className="infection diarySection">
          <div className="fieldset radio"> {state.infectionSelect} <input type="checkbox" id="infectionSelect" name="infectionSelect" checked={state.infectionSelect} onChange={checkboxHandler} />
            <label>{labels.infection}</label>
          </div>
          <div className="fieldset text logic">
            <label className="mobile">{labels.how_long}</label>
            <input type="text" id="infectionHowLong" name="infectionHowLong" onChange={inputsHandler} />
          </div>
          <div className="options">
            <label className="mobile500" dangerouslySetInnerHTML={{ __html: labels.how_severe }}></label>
            <div className="fieldset radio logic">
              <input type="radio" id="infection1" className="infectionOne one" name="infection" value="1" onChange={inputsHandler} />
            </div>
            <div className="fieldset radio logic">
              <input type="radio" id="infection2" className="infectionTwo two" name="infection" value="2" onChange={inputsHandler} />
            </div>
            <div className="fieldset radio logic">
              <input type="radio" id="infection3" className="infectionThree three" name="infection" value="3" onChange={inputsHandler} />
            </div>
            <div className="fieldset radio logic">
              <input type="radio" id="infection4" className="infectionFour four" name="infection" value="4" onChange={inputsHandler} />
            </div>
            <div className="fieldset radio logic">
              <input type="radio" id="infection5" className="infectionFive five" name="infection" value="5" onChange={inputsHandler} />
            </div>
          </div>
        </div>
        <div className="infection fever diarySection">
          <div className="fieldset radio">
            <input type="checkbox" id="feverSelect" name="feverSelect" checked={state.feverSelect} onChange={checkboxHandler} />
            <label>{labels.fever}</label>
          </div>
          <div className="fieldset text logic">
            <label className="mobile">{labels.how_long}</label>
            <input type="text" id="feverHowLong" name="feverHowLong" onChange={inputsHandler} />
          </div>
          <div className="options">
            <label className="mobile500" dangerouslySetInnerHTML={{ __html: labels.how_severe }}></label>
            <div className="fieldset radio">
              <input type="radio" id="fever1" className="feverOne one" name="fever" value="1" onChange={inputsHandler} />
            </div>
            <div className="fieldset radio logic">
              <input type="radio" id="fever2" className="feverTwo two" name="fever" value="2" onChange={inputsHandler} />
            </div>
            <div className="fieldset radio logic">
              <input type="radio" id="fever3" className="feverThree three" name="fever" value="3" onChange={inputsHandler} />
            </div>
            <div className="fieldset radio logic">
              <input type="radio" id="fever4" className="feverFour four" name="fever" value="4" onChange={inputsHandler} />
            </div>
            <div className="fieldset radio logic">
              <input type="radio" id="fever5" className="feverFive five" name="fever" value="5" onChange={inputsHandler} />
            </div>
          </div>
        </div>
        <div className="infection muscle diarySection">
          <div className="fieldset radio">
            <input type="checkbox" id="musclePainSelect" name="muscleSelect" checked={state.musclePainSelect} onChange={checkboxHandler} />
            <label>{labels.muscle_pain}</label>
          </div>
          <div className="fieldset text logic">
            <label className="mobile">{labels.how_long}</label>
            <input type="text" id="musclePainHowLong" name="muscleHowLong" onChange={inputsHandler} />
          </div>
          <div className="options">
            <label className="mobile500" dangerouslySetInnerHTML={{ __html: labels.how_severe }}></label>
            <div className="fieldset radio logic">
              <input type="radio" id="muscle1" className="muscleOne one" name="muscle" value="1" onChange={inputsHandler} />
            </div>
            <div className="fieldset radio logic">
              <input type="radio" id="muscle2" className="muscleTwo two" name="muscle" value="2" onChange={inputsHandler} />
            </div>
            <div className="fieldset radio logic">
              <input type="radio" id="muscle3" className="muscleThree three" name="muscle" value="3" onChange={inputsHandler} />
            </div>
            <div className="fieldset radio logic">
              <input type="radio" id="muscle4" className="muscleFour four" name="muscle" value="4" onChange={inputsHandler} />
            </div>
            <div className="fieldset radio logic">
              <input type="radio" id="muscle" className="muscleFive five" name="muscle" value="5" onChange={inputsHandler} />
            </div>
          </div>
        </div>
        <div className="infection pressure diarySection">
          <div className="fieldset radio">
            <input type="checkbox" id="bloodPressureSelect" name="pressureSelect" checked={state.bloodPressureSelect} onChange={checkboxHandler} />
            <label>{labels.blood_pressure}</label>
          </div>
          <div className="fieldset text logic">
            <label className="mobile">{labels.how_long}</label>
            <input type="text" id="pressureHowLong" name="pressureHowLong" onChange={inputsHandler} />
          </div>
          <div className="options">
            <label className="mobile500" dangerouslySetInnerHTML={{ __html: labels.how_severe }}></label>
            <div className="fieldset radio logic">
              <input type="radio" id="pressure1" className="pressureOne one" name="pressure" value="1" onChange={inputsHandler} />
            </div>
            <div className="fieldset radio logic">
              <input type="radio" id="pressure2" className="pressureTwo two" name="pressure" value="2" onChange={inputsHandler} />
            </div>
            <div className="fieldset radio logic">
              <input type="radio" id="pressure3" className="pressureThree three" name="pressure" value="3" onChange={inputsHandler} />
            </div>
            <div className="fieldset radio logic">
              <input type="radio" id="pressure4" className="pressureFour four" name="pressure" value="4" onChange={inputsHandler} />
            </div>
            <div className="fieldset radio logic">
              <input type="radio" id="pressure5" className="pressureFive five" name="pressure" value="5" onChange={inputsHandler} />
            </div>
          </div>
        </div>
        <div className="infection Lymph diarySection">
          <div className="fieldset radio">
            <input type="checkbox" id="lymphNodeSelect" name="lymphSelect" checked={state.lymphNodeSelect} onChange={checkboxHandler} />
            <label dangerouslySetInnerHTML={{ __html: labels.swollen }}></label>
          </div>
          <div className="fieldset text logic">
            <textarea id="lymphNodesHowLong" name="lymphHowLong" onChange={inputsHandler} rows="4"></textarea>
          </div>
          <div className="options">
            <label className="mobile500" dangerouslySetInnerHTML={{ __html: labels.how_severe }}></label>
            <div className="fieldset radio logic">
              <input type="radio" id="lymph1" className="lymphOne one" name="lymph" value="1" onChange={inputsHandler} />
            </div>
            <div className="fieldset radio logic">
              <input type="radio" id="lymph2" className="lymphTwo two" name="lymph" value="2" onChange={inputsHandler} />
            </div>
            <div className="fieldset radio logic">
              <input type="radio" id="lymph3" className="lymphThree three" name="lymph" value="3" onChange={inputsHandler} />
            </div>
            <div className="fieldset radio logic">
              <input type="radio" id="lymph4" className="lymphFour four" name="lymph" value="4" onChange={inputsHandler} />
            </div>
            <div className="fieldset radio logic">
              <input type="radio" id="lymph5" className="lymphFive five" name="lymph" value="5" onChange={inputsHandler} />
            </div>
          </div>
        </div>
        <div className="infection mouth diarySection">
          <div className="fieldset radio">
            <input type="checkbox" id="mouthSelect" name="mouthThroatSelect" checked={state.mouthThroatSelect} onChange={checkboxHandler} />
            <label>{labels.throat_problem}</label>
          </div>
          <div className="fieldset text logic">
            <label className="mobile">{labels.how_long}</label>
            <input type="text" id="mouthThroatHowLong" name="mouthHowLong" onChange={inputsHandler} />
          </div>
          <div className="options">
            <label className="mobile500" dangerouslySetInnerHTML={{ __html: labels.how_severe }}></label>
            <div className="fieldset radio logic">
              <input type="radio" id="mouth1" className="mouthOne one" name="mouth" value="1" onChange={inputsHandler} />
            </div>
            <div className="fieldset radio logic">
              <input type="radio" id="mouth2" className="mouthTwo two" name="mouth" value="2" onChange={inputsHandler} />
            </div>
            <div className="fieldset radio logic">
              <input type="radio" id="mouth3" className="mouthThree three" name="mouth" onChange={inputsHandler} value="3" />
            </div>
            <div className="fieldset radio logic">
              <input type="radio" id="mouth4" className="mouthFour four" name="mouth" onChange={inputsHandler} value="4" />
            </div>
            <div className="fieldset radio logic">
              <input type="radio" id="mouth5" className="mouthFive five" name="mouth" onChange={inputsHandler} value="5" />
            </div>
          </div>
        </div>
        <div className="infection tummy diarySection">
          <div className="fieldset radio">
            <input type="checkbox" id="tummySelect" name="tummySelect" checked={state.tummySelect} onChange={checkboxHandler} />
            <label>{labels.tummy_issues}</label>
          </div>
          <div className="fieldset text logic">
            <label className="mobile">{labels.how_long}</label>
            <input type="text" id="tummyHowLong" name="tummyHowLong" onChange={inputsHandler} />
          </div>
          <div className="options">
            <label className="mobile500" dangerouslySetInnerHTML={{ __html: labels.how_severe }}></label>
            <div className="fieldset radio logic">
              <input type="radio" id="tummy1" className="tummyOne one" name="tummy" value="1" onChange={inputsHandler} />
            </div>
            <div className="fieldset radio logic">
              <input type="radio" id="tummy2" className="tummyTwo two" name="tummy" value="2" onChange={inputsHandler} />
            </div>
            <div className="fieldset radio logic">
              <input type="radio" id="tummy3" className="tummyThree three" name="tummy" value="3" onChange={inputsHandler} />
            </div>
            <div className="fieldset radio logic">
              <input type="radio" id="tummy4" className="tummyFour four" name="tummy" value="4" onChange={inputsHandler} />
            </div>
            <div className="fieldset radio logic">
              <input type="radio" id="tummy5" className="tummyFive five" name="tummy" value="5" onChange={inputsHandler} />
            </div>
          </div>
        </div>
        <div className="infection weight diarySection">
          <div className="fieldset radio">
            <input type="checkbox" id="weightSelect" name="weightSelect" checked={state.weightSelect} onChange={checkboxHandler} />
            <label>{labels.weight_change}</label>
          </div>
          <div className="fieldset text logic">
            <label className="mobile">{labels.how_long}</label>
            <input type="text" id="weightHowLong" name="weightHowLong" onChange={inputsHandler} />
          </div>
          <div className="options">
            <label className="mobile500" dangerouslySetInnerHTML={{ __html: labels.how_severe }}></label>
            <div className="infused">
              <div className="fieldset radio">
                <input type="radio" id="gain" className="infusedYes" name="weight" value={labels.gain} onChange={inputsHandler} />
                <label>{labels.gain}</label>
              </div>
              <div className="fieldset radio">
                <input type="radio" id="loss" className="infusedNo" name="weight" value={labels.loss} onChange={inputsHandler} />
                <label>{labels.loss}</label>
              </div>
            </div>
          </div>
        </div>
        <div className="infection water diarySection">
          <div className="fieldset radio">
            <input type="checkbox" id="waterSelect" name="waterSelect" checked={state.waterSelect} onChange={checkboxHandler} />
            <label dangerouslySetInnerHTML={{ __html: labels.water_retention }}></label>
          </div>
          <div className="fieldset text logic">
            <textarea id="waterHowLong" name="waterHowLong" onChange={inputsHandler} rows="4"></textarea>
          </div>
          <div className="options">
            <label className="mobile500" dangerouslySetInnerHTML={{ __html: labels.how_severe }}></label>
            <div className="fieldset radio logic">
              <input type="radio" id="water1" className="waterOne one" name="water" value="1" onChange={inputsHandler} />
            </div>
            <div className="fieldset radio logic">
              <input type="radio" id="water2" className="waterTwo two" name="water" value="2" onChange={inputsHandler} />
            </div>
            <div className="fieldset radio logic">
              <input type="radio" id="water3" className="waterThree three" name="water" value="3" onChange={inputsHandler} />
            </div>
            <div className="fieldset radio logic">
              <input type="radio" id="water4" className="waterFour four" name="water" value="4" onChange={inputsHandler} />
            </div>
            <div className="fieldset radio logic">
              <input type="radio" id="water5" className="waterFive five" name="water" value="5" onChange={inputsHandler} />
            </div>
          </div>
        </div>
        <div className="infection fatigue diarySection">
          <div className="fieldset radio">
            <input type="checkbox" id="fatigueSelect" name="fatigueSelect" checked={state.fatigueSelect} onChange={checkboxHandler} />
            <label>{labels.fatigue}</label>
          </div>
          <div className="fieldset text logic">
            <label className="mobile">{labels.how_long}</label>
            <input type="text" id="fatigueHowLong" name="fatigueHowLong" onChange={inputsHandler} />
          </div>
          <div className="options">
            <label className="mobile500" dangerouslySetInnerHTML={{ __html: labels.how_severe }}></label>
            <div className="fieldset radio logic">
              <input type="radio" id="fatigue1" className="fatigueOne one" name="fatigue" value="1" onChange={inputsHandler} />
            </div>
            <div className="fieldset radio logic">
              <input type="radio" id="fatigue2" className="fatigueTwo two" name="fatigue" value="2" onChange={inputsHandler} />
            </div>
            <div className="fieldset radio logic">
              <input type="radio" id="fatigue3" className="fatigueThree three" name="fatigue" value="3" onChange={inputsHandler} />
            </div>
            <div className="fieldset radio logic">
              <input type="radio" id="fatigue4" className="fatigueFour four" name="fatigue" value="4" onChange={inputsHandler} />
            </div>
            <div className="fieldset radio logic">
              <input type="radio" id="fatigue5" className="fatigueFive five" name="fatigue" value="5" onChange={inputsHandler} />
            </div>
          </div>
        </div>
        <div className="infection breath diarySection">
          <div className="fieldset radio">
            <input type="checkbox" id="breathSelect" name="breathSelect" checked={state.breathSelect} onChange={checkboxHandler} />
            <label>{labels.breath_shortnetss}</label>
          </div>
          <div className="fieldset text logic">
            <label className="mobile">{labels.how_long}</label>
            <input type="text" id="breathHowLong" name="breathHowLong" onChange={inputsHandler} />
          </div>
          <div className="options">
            <label className="mobile500" dangerouslySetInnerHTML={{ __html: labels.how_severe }}></label>
            <div className="fieldset radio logic">
              <input type="radio" id="breath1" className="breathOne one" name="breath" value="1" onChange={inputsHandler} />
            </div>
            <div className="fieldset radio logic">
              <input type="radio" id="breath2" className="breathTwo two" name="breath" value="2" onChange={inputsHandler} />
            </div>
            <div className="fieldset radio logic">
              <input type="radio" id="breath3" className="breathThree three" name="breath" value="3" onChange={inputsHandler} />
            </div>
            <div className="fieldset radio logic">
              <input type="radio" id="breath4" className="breathFour four" name="breath" value="4" onChange={inputsHandler} />
            </div>
            <div className="fieldset radio logic">
              <input type="radio" id="breath5" className="breathFive five" name="breath" value="5" onChange={inputsHandler} />
            </div>
          </div>
        </div>
        <div className="infection sweats diarySection">
          <div className="fieldset radio">
            <input type="checkbox" id="sweatsSelect" name="sweatsSelect" checked={state.sweatsSelect} onChange={checkboxHandler} />
            <label>{labels.night_sweats}</label>
          </div>
          <div className="fieldset text logic">
            <label className="mobile">{labels.how_long}</label>
            <input type="text" id="sweatsHowLong" name="sweatsHowLong" onChange={inputsHandler} />
          </div>
          <div className="options">
            <label className="mobile500" dangerouslySetInnerHTML={{ __html: labels.how_severe }}></label>
            <div className="fieldset radio logic">
              <input type="radio" id="sweats1" className="sweatsOne one" name="sweats" value="1" onChange={inputsHandler} />
            </div>
            <div className="fieldset radio logic">
              <input type="radio" id="sweats2" className="sweatsTwo two" name="sweats" value="2" onChange={inputsHandler} />
            </div>
            <div className="fieldset radio logic">
              <input type="radio" id="sweats3" className="sweatsThree three" name="sweats" value="3" onChange={inputsHandler} />
            </div>
            <div className="fieldset radio logic">
              <input type="radio" id="sweats4" className="sweatsFour four" name="sweats" value="4" onChange={inputsHandler} />
            </div>
            <div className="fieldset radio logic">
              <input type="radio" id="sweats5" className="sweatsFive five" name="sweats" value="5" onChange={inputsHandler} />
            </div>
          </div>
        </div>
        <div className="infection rash diarySection">
          <div className="fieldset radio">
            <input type="checkbox" id="rashSelect" name="rashSelect" checked={state.rashSelect} onChange={checkboxHandler} />
            <label>{labels.skin_rash}</label>
          </div>
          <div className="fieldset text logic">
            <label className="mobile">{labels.how_long}</label>
            <input type="text" id="rashHowLong" name="rashHowLong" onChange={inputsHandler} />
          </div>
          <div className="options">
            <label className="mobile500" dangerouslySetInnerHTML={{ __html: labels.how_severe }}></label>
            <div className="fieldset radio logic">
              <input type="radio" id="rash1" className="rashOne one" name="rash" value="1" onChange={inputsHandler} />
            </div>
            <div className="fieldset radio logic">
              <input type="radio" id="rash2" className="rashTwo two" name="rash" value="2" onChange={inputsHandler} />
            </div>
            <div className="fieldset radio logic">
              <input type="radio" id="rash3" className="rashThree three" name="rash" value="3" onChange={inputsHandler} />
            </div>
            <div className="fieldset radio logic">
              <input type="radio" id="rash4" className="rashFour four" name="rash" value="4" onChange={inputsHandler} />
            </div>
            <div className="fieldset radio logic">
              <input type="radio" id="rash5" className="rashFive five" name="rash" value="5" onChange={inputsHandler} />
            </div>
          </div>
        </div>
        <div className="infection itching diarySection">
          <div className="fieldset radio">
            <input type="checkbox" id="itchingSelect" name="itchingSelect" checked={state.itchingSelect} onChange={checkboxHandler} />
            <label>{labels.itching}</label>
          </div>
          <div className="fieldset text logic">
            <label className="mobile">{labels.how_long}</label>
            <input type="text" id="itchingHowLong" name="itchingHowLong" onChange={inputsHandler} />
          </div>
          <div className="options">
            <label className="mobile500" dangerouslySetInnerHTML={{ __html: labels.how_severe }}></label>
            <div className="fieldset radio logic">
              <input type="radio" id="itching1" className="itchingOne one" name="itching" value="1" onChange={inputsHandler} />
            </div>
            <div className="fieldset radio logic">
              <input type="radio" id="itching2" className="itchingTwo two" name="itching" value="2" onChange={inputsHandler} />
            </div>
            <div className="fieldset radio logic">
              <input type="radio" id="itching3" className="itchingThree three" name="itching" value="3" onChange={inputsHandler} />
            </div>
            <div className="fieldset radio logic">
              <input type="radio" id="itching4" className="itchingFour four" name="itching" value="4" onChange={inputsHandler} />
            </div>
            <div className="fieldset radio logic">
              <input type="radio" id="itching5" className="itchingFive five" name="itching" value="5" onChange={inputsHandler} />
            </div>
          </div>
        </div>
        <div className="infection cough diarySection">
          <div className="fieldset radio">
            <input type="checkbox" id="coughSelect" name="coughSelect" checked={state.coughSelect} onChange={checkboxHandler} />
            <label>{labels.cough}</label>
          </div>
          <div className="fieldset text logic">
            <label className="mobile">{labels.how_long}</label>
            <input type="text" id="coughHowLong" name="coughHowLong" onChange={inputsHandler} />
          </div>
          <div className="options">
            <label className="mobile500" dangerouslySetInnerHTML={{ __html: labels.how_severe }}></label>
            <div className="fieldset radio logic">
              <input type="radio" id="cough1" className="coughOne one" name="cough" value="1" onChange={inputsHandler} />
            </div>
            <div className="fieldset radio logic">
              <input type="radio" id="cough2" className="coughTwo two" name="cough" value="2" onChange={inputsHandler} />
            </div>
            <div className="fieldset radio logic">
              <input type="radio" id="cough3" className="coughThree three" name="cough" value="3" onChange={inputsHandler} />
            </div>
            <div className="fieldset radio logic">
              <input type="radio" id="cough4" className="coughFour four" name="cough" value="4" onChange={inputsHandler} />
            </div>
            <div className="fieldset radio logic">
              <input type="radio" id="cough5" className="coughFive five" name="cough" value="5" onChange={inputsHandler} />
            </div>
          </div>
        </div>
        <div className="infection pain diarySection">
          <div className="fieldset radio">
            <input type="checkbox" id="painSelect" name="painSelect" checked={state.painSelect} onChange={checkboxHandler} />
            <label>{labels.pain}</label>
          </div>
          <div className="fieldset text logic">
            <label className="mobile">{labels.how_long}</label>
            <input type="text" id="painHowLong" name="painHowLong" onChange={inputsHandler} />
          </div>
          <div className="options">
            <label className="mobile500" dangerouslySetInnerHTML={{ __html: labels.how_severe }}></label>
            <div className="fieldset radio logic">
              <input type="radio" id="pain1" className="painOne one" name="pain" value="1" onChange={inputsHandler} />
            </div>
            <div className="fieldset radio logic">
              <input type="radio" id="pain2" className="painTwo two" name="pain" value="2" onChange={inputsHandler} />
            </div>
            <div className="fieldset radio logic">
              <input type="radio" id="pain3" className="painThree three" name="pain" value="3" onChange={inputsHandler} />
            </div>
            <div className="fieldset radio logic">
              <input type="radio" id="pain4" className="painFour four" name="pain" value="4" onChange={inputsHandler} />
            </div>
            <div className="fieldset radio logic">
              <input type="radio" id="pain5" className="painFive five" name="pain" value="5" onChange={inputsHandler} />
            </div>
          </div>
        </div>
        <div className="infection otherSymptoms diarySection">
          <div className="fieldset radio">
            <input type="checkbox" id="otherSymptomsSelect" name="otherSymptomsSelect" checked={state.otherSymptomsSelect} onChange={checkboxHandler} />
            <label dangerouslySetInnerHTML={{ __html: labels.other_symptoms }}></label>
          </div>
          <div className="fieldset text logic">
            <textarea id="otherSymptomsHowLong" name="otherSymptomsHowLong" onChange={inputsHandler} rows="4"></textarea>
          </div>
          <div className="options">
            <label className="mobile500" dangerouslySetInnerHTML={{ __html: labels.how_severe }}></label>
            <div className="fieldset radio logic">
              <input type="radio" id="otherSymptoms1" className="otherSymptomsOne one" name="otherSymptoms" value="1" onChange={inputsHandler} />
            </div>
            <div className="fieldset radio logic">
              <input type="radio" id="otherSymptoms2" className="otherSymptomsTwo two" name="otherSymptoms" value="2" onChange={inputsHandler} />
            </div>
            <div className="fieldset radio logic">
              <input type="radio" id="otherSymptoms3" className="otherSymptomsThree three" name="otherSymptoms" value="3" onChange={inputsHandler} />
            </div>
            <div className="fieldset radio logic">
              <input type="radio" id="otherSymptoms4" className="otherSymptomsFour four" name="otherSymptoms" value="4" onChange={inputsHandler} />
            </div>
            <div className="fieldset radio logic">
              <input type="radio" id="otherSymptoms5" className="otherSymptomsFive five" name="otherSymptoms" value="5" onChange={inputsHandler} />
            </div>
          </div>
        </div>
        <div className="symptomNotes diarySection">
          <label>{labels.impact_on_life}</label>
          <textarea id="symptomsImpact" name="symptomsImpact" rows="4" onChange={inputsHandler}></textarea>
        </div>
        <div className="diarySection">
          <input type="button" className="submit" value={labels.generate_pdf} onClick={downloadForm} />
        </div>
      </form>
    </div>
  )
}

export default DiaryForm