import React from "react"
import Markdown from "../Markdown"

const Module = ({ id, style, content }) => {

  return (
    <div id={id} className="section-module"
      style={JSON.parse(`{${style == null ? "" : style.internal.content}}`)}
    >
      
      {content.childMdx && <Markdown>{content.childMdx.body}</Markdown>}
    </div>
  )
}

export default Module
